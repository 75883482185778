import request from '@/utils/request'

// 查询各种分类
export function getClassify(params) {
  return request({
    url: 'api/classify',
    method: 'get',
    params
  })
}


export function getShareParams(params) {
  return request({
    url: 'draw/share',
    method: 'get',
    params
  })
}

export function getIndexData(params) {
  return request({
    url: 'draw/index',
    method: 'get',
    params
  })
}

export function login(data) {
  return request({
    url: 'draw/login',
    method: 'post',
    data
  })
}

export function raffle() {
  return request({
    url: `draw/smoke/1`,
    method: 'post',
  })
}
