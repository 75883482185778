<template>
  <div class="container">
    <div class="banner-wrapper">
      <img src="../../assets/icon/img-banner.png" alt="">
    </div>
    <div class="bg"></div>
    <div class="luck-wrapper">
      <div class="lucky-box">
        <div class="title">1分钱活动（抽奖）</div>
        <LuckyGrid
          v-if="luckWidth"
          ref="myLucky"
          :width="luckWidth"
          :height="luckWidth"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          :activeStyle="activeStyle"
          @start="startCallback"
          @end="endCallback"
        />
      </div>
    </div>
    <div class="rule-wrapper">
      <div class="rule-info">
      <div class="title">奖品设置</div>
      <div class="list">
        <div class="item" v-for="(item, index) in pageData.infoList" :key="index">
          <div class="label">{{item.label}}</div>
          <div class="value">{{item.val}}</div>
        </div>
      </div>
    </div>
    </div>
    <div class="six-item-wrapper">
      <div class="wrapper">
        <div class="title">6件套服务</div>
        <div class="item-wrapper">
          <div class="item">
            <img src="../../assets/icon/icon-setting.png" alt="">
            <div class="text">展会搭建</div>
          </div>
          <div class="item">
            <img src="../../assets/icon/icon-hotel.png" alt="">
            <div class="text">酒店预定</div>
          </div>
          <div class="item">
            <img src="../../assets/icon/icon-design.png" alt="">
            <div class="text">设计印刷</div>
          </div>
          <div class="item">
            <img src="../../assets/icon/icon-sofa.png" alt="">
            <div class="text">展具租赁</div>
          </div>
          <div class="item">
            <img src="../../assets/icon/icon-video.png" alt="">
            <div class="text">宣传视频</div>
          </div>
          <div class="item">
            <img src="../../assets/icon/icon-homemoney.png" alt="">
            <div class="tips">合规·节税</div>
            <div class="text">税务筹划</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="priceModelData.show" class="pice-model-dialog">
      <div class="popup-wrapper" @click="closePopup">
        <img class="icon-arrow" src="../../assets/icon/icon-share.png" alt="">
        <div class="price-model-content" @click.stop="">
          <div class="box">
            <div class="img-wrapper">
              <img :src="priceModelData.url" alt="">
            </div>
            <div class="text">{{ priceModelData.text }}</div>
            <van-button round block type="primary">转发朋友圈领奖</van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="loginModel" position="bottom" class="login-model-dialog" :close-on-click-overlay="false" @closed="popupClose">
      <div class="login-model-content">
        <div class="title">完善信息</div>
        <div class="desc">请完善你的个人信息，以能获取奖品</div>
        <div class="form">
          <van-form @submit="onSubmit" class="vant-form">
            <div class="nickname-wrapper">
              <van-field
                v-model="loginForm.nickname"
                name="username"
                label="称呼"
                placeholder="请输入您的称呼"
              />
              <van-radio-group v-model="loginForm.gender" direction="horizontal" class="radio-group">
                <van-radio name="男">先生</van-radio>
                <van-radio name="女">女士</van-radio>
              </van-radio-group>
            </div>
            <van-field
              v-model="loginForm.mobile"
              type="number"
              name="mobile"
              label="手机号码"
              placeholder="请输入手机号码"
            />
            <div style="margin-bottom: 30px;">
              <van-button round block native-type="submit" type="primary" :disabled="submitDisabled">确定</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
//https://100px.net/demo/grid.html
const cellImg= {
  src: require('../../assets/icon/block01.png'),
  width: '93%',
  height: '94%',
  top: '3%'
}

const prizeImg = {
  src: require('../../assets/icon/ticket-blue.png'),
  width: '80%',
  top: '10%'
}
const btnImg = {
  src: require('../../assets/icon/block02.png'),
  width: '100%',
  height: '100%'
}
import { getIndexData, login, raffle } from '@/api/index'
import { wxShareConfig } from '../../utils/share'
import { Notify, Dialog } from 'vant';
export default {
  data () {
    return {
      luckWidth: '',

      win: false,
      loading: false,

      loginModel: false,

      priceModelData: {
        show: false,
        url: 'https://tt-sanhao.oss-cn-shenzhen.aliyuncs.com/tt/upload/202308/cadd75003516448599766077d5520c93',
        text: '恭喜获得1等奖-1分钱搭建'
      },

      loginForm: {
        gender: '男',
        mobile: '',
        nickname: '',
        lotteryId: 1
      },
      
      blocks: [
        { padding: '8px' },
      ],
      activeStyle: {
        background: '#00BB78'
      },
      prizes: [
        { x: 0, y: 0, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 1, y: 0, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 2, y: 0, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 2, y: 1, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 2, y: 2, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 1, y: 2, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 0, y: 2, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
        { x: 0, y: 1, borderRadius: '12px', imgs: [cellImg, prizeImg], fonts: [{ text: '一分钱易拉宝', top: '65%', fontColor: '#35453F', fontSize: '12px' }] },
      ],
      buttons: [{
        x: 1, y: 1,
        background: '#7f95d1',
        fonts: [{ text: '抽奖', top: '32%', fontSize: '24px', fontColor: '#fff', fontWeight: 'bold' }],
        imgs: [btnImg]
      }],
      defaultStyle: {
        background: '#b8c5f2'
      },

      pageData: {
        flag: false,
        infoList: [],
        itemList: []
      },

      isWxBrowser: true,

      startFlag: false
    }
  },
  computed: {
    submitDisabled() {
      const { gender, mobile, nickname } = this.loginForm
      return !(gender && mobile && nickname)
    }
  },
  mounted() {

    this.getData()
    this.initLucky()
    wxShareConfig()
  },
  methods: {
    // 是否是微信浏览器
    isWechat(){  
      var ua = window.navigator.userAgent.toLowerCase();  
      if(ua.match(/micromessenger/i) == 'micromessenger'){  
        return true;  
      }else{  
        return false;  
      }  
    },
    popupClose() {
      document.documentElement.scrollTop && (document.documentElement.scrollTop = 0)
      document.body.scrollTop && (document.body.scrollTop = 0)
    },
    closePopup() {
      this.priceModelData.show = false
    },
    initLucky() {
      const w = document.body.clientWidth
      const paddingNum = 24
      this.luckWidth = (w - paddingNum) + 'px'

    },

    getData() {
      getIndexData({ lotteryId: 1 }).then(res => {
        this.pageData = res
        const { itemList, infoList, flag, lotteryRecord, startFlag } = res
        this.startFlag = startFlag
        if(!startFlag) {
          Dialog.alert({
            message: '活动未开始',
            theme: 'round-button',
            confirmButtonColor: '#07c160'
          }).then(() => {
            // on close
          });
        } else if(flag) {
          // 已抽奖
          if(lotteryRecord) {
            const itemId = lotteryRecord.itemId
            const itemData = itemList.find(item => item.id == itemId)
            const url = itemData.imgUrl
            const text = `恭喜获得-${lotteryRecord.prizeName}`
            this.priceModelData = {
              show: true,
              url,
              text
            }
          }
          this.buttons[0].fonts[0].text = '已中奖'
          this.win = true
        } else {
          this.loginModel = true
        }
        this.prizes = itemList.map((item, index) => {
          const { imgUrl, prizeName } = item
          const prizeImg = {
            src: imgUrl,
            width: '55%',
            top: '12%'
          }
          const fontItem = {
            text: prizeName,
            top: '72%',
            fontColor: '#35453F',
            fontSize: '12px'
          }
          return {
            ...this.prizes[index],
            imgs: [cellImg, prizeImg],
            fonts: [fontItem]
          }
        })
      })
    },
    onSubmit() {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(this.loginForm.mobile)) {
        // 合法手机号
        login(this.loginForm).then(res => {
          this.loginModel = false
          this.$store.commit('SET_TOKEN', res.token)
        })
      } else {
        Notify({ type: 'warning', message: '手机号码格式错误' });
      }
    },
    // 点击抽奖按钮会触发star回调
    startCallback () {
      // 调用抽奖组件的play方法开始游戏
      if(this.win || this.loading || !this.startFlag) return
      // 模拟调用接口异步抽奖
      this.loading = true
      raffle().then(res => {
        this.loading = false
        this.win = true
        console.log(res,'>>>中将接口信息');
        this.$refs.myLucky.play()
        this.buttons[0].fonts[0].text = '正在抽奖'
        setTimeout(() => {
          // 假设后端返回的中奖索引是0
          const index = this.pageData.itemList.findIndex(item => item.id == res.prizeId)
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(index)
          
        }, 3000)
      })
    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      console.log(prize,'>>>抽奖结束回调prize');
      this.buttons[0].fonts[0].text = '已中奖'
      this.priceModelData = {
        show: true,
        url: prize.imgs[1].src,
        text: `恭喜获得-${prize.fonts[0].text}`
      }
    },
  }
}
</script>
<style scoped lang="scss">
.container {
  background: #F6F6F6;
  .banner-wrapper {
    width: 100%;
    font-size: 0;
    img {
      width: 100%;
    }
  }
  .bg {
    width: 100%;
    height: calc(100vw * 0.32);
    background: linear-gradient(180deg, #DBFFFC 0%, rgba(246,246,246,0) 100%);
  }
  .luck-wrapper {
    margin-top: calc(100vw * -0.32);
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    .lucky-box {
      background: #fff;
      border-radius: 12px;
      padding: 16px 0 0;
      .title {
        font-weight: bold;
        color: #323232;
        line-height: 1;
        font-size: 16px;
        position: relative;
        padding-left: 26px;
        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 16px;
          background: #00BB78;
          position: absolute;
          left: 15px;
          top: 0;
        }
      }
    }
  }
  .rule-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    margin-top: 8px;
  }
  .rule-info {
    background: #fff;
    border-radius: 12px;
    padding: 16px 0 8px;
    .title {
      font-weight: bold;
      color: #323232;
      line-height: 24px;
      font-size: 16px;
      position: relative;
      padding-left: 24px;
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #00BB78;
        position: absolute;
        left: 14px;
        top: 4px;
      }
    }
    .list {
      font-weight: 400;
      color: #666666;
      line-height: 33rpx;
      font-size: 12px;
      padding: 0 24px;
      margin-top: 6px;
      box-sizing: border-box;
      .item {
        display: flex;
        justify-content: space-between;
        height: 36px;
        align-items: center;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        & + .item::before {
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          top: 0;
          background: #E6E6E6;
          content: '';
          display: inline-block;
          transform: scaleY(.5);
        }
        .label {
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .value {
          width: 50%;
          font-size: 24rpx;
          color: #666666;
          font-weight: normal;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .six-item-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px 12px;
    .wrapper {
      padding: 12px 0;
      border-radius: 12px;
      background: #fff;
      .title {
        font-weight: bold;
        color: #323232;
        line-height: 24px;
        font-size: 16px;
        position: relative;
        padding-left: 24px;
        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 16px;
          background: #00BB78;
          position: absolute;
          left: 14px;
          top: 4px;
        }
      }
      .item-wrapper {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 16px;
          position: relative;
          img {
            width: 34%;
          }
          .tips {
            position: absolute;
            color: #fff;
            background: #FF2920;
            font-size: 18px;
            transform: scale(.5);
            padding: 4px 6px;
            border-radius: 50px 50px 50px 2px;
            line-height: 1;
            top: -6px;
            right: -9px;
          }
          .text {
            text-align: center;
            font-weight: 400;
            color: #323232;
            line-height: 36rpx;
            font-size: 13px;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
.popup-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  .icon-arrow {
    width: 107px;
    height: 110px;
    position: absolute;
    top: 10px;
    right: 0;
  }
}
.pice-model-dialog {
  background: none;
}
.price-model-content {
  width: 70vw;
  background: #fff;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .box {
    width: 100%;
    padding: 40px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    .img-wrapper {
      width: 35%;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 17px;
      font-weight: bold;
      color: #35453F;
      margin-bottom: 40px;
    }
  }
}
.login-model-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 12px 10px;
  box-sizing: border-box;
  width: 100%;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #323232;
    line-height: 25px;
    margin-bottom: 6px;
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: #00BB78;
    line-height: 16px;
  }
}
</style>
<style lang="scss">
.login-model-dialog {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  .form {
    width: 100%;
  }
  .nickname-wrapper {
    position: relative;
    .radio-group {
      position: absolute;
      right: 0;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      .van-radio__label {
        font-size: 14px;
        color: #333;
      }
    }
    .van-cell__value {
      padding-right: 130px;
    }
  }
  .vant-form {
    width: 100%;
    margin-top: 20px;
    .van-cell {
      background: #F6F6F6;
      border-radius: 12px;
      margin-bottom: 12px;
      .van-field__label {
        font-size: 14px;
        color: #323232;
        width: 68px;
      }
    }
    .van-button {
      width: 100%;
    }
    .van-button--primary {
      background: #00DA80;
    }
    .van-radio__icon--checked .van-icon {
      background: #00BB78;
      border-color: #00BB78;
    }
  }
}
</style>