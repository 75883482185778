import axios from 'axios'
import Cookies from 'js-cookie'
import store from '../store/index'
import { Dialog } from 'vant';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/proxyApi', // api 的 base_url
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000 // 请求超时时间
})

function errorRequest(code,msg){
  if (code) {
    if (code === 401) {
      store.dispatch('LogOut').then(() => {
        // 用户登录界面提示
        Cookies.set('point', 401)
        location.reload()
      })
    } else if (code === 403) {
      router.push({ path: '/401' })
    } else {
      if (msg !== undefined) {
        Dialog.alert({
          message: msg,
          theme: 'round-button',
          confirmButtonColor: '#07c160'
        }).then(() => {
          // on close
        });
      }
    }
  } else {
    // Notification.error({
    //   title: '接口请求失败',
    //   duration: 5000
    // })
  }
}

export function getToken() {
  return Cookies.get('token') || sessionStorage.getItem('token') || store.state.token
}

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['clientId'] = 'd584d4e0-63a6-4c54-877b-5dfeda389c4e'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.data.code
    const msg = response.data.msg
    const status = response.status
    if(status === 401) {
      errorRequest(status,'登录状态失效')
      return
    }
    if (code==200){
      return response.data.data
    }else{
      errorRequest(code,msg)
    }
  },
  error => {
    console.log(error,'errorrrr');
    // 兼容blob下载出错json提示
    if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
      const reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = function(e) {
        const errorMsg = JSON.parse(reader.result).message
        // Notification.error({
        //   title: errorMsg,
        //   duration: 5000
        // })
      }
    } else {
      let code = 0
      let msg = undefined
      try {
        code = error.response.data.code
        msg = error.response.data.msg
      } catch (e) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
          // Notification.error({
          //   title: '网络请求超时',
          //   duration: 5000
          // })
          return Promise.reject(error)
        }
      }
      errorRequest(code,msg)
    }
    return Promise.reject(error)
  }
)
export default service
